<template>
  <base-wrapper>
    <div class="space-y-6">
      <div v-if="stats" class="grid grid-cols-7 gap-4">
        <base-widget
          v-for="[stat, value] in Object.entries(stats)"
          :key="stat"
          :label="getStatusBadge(stat).text"
          :value="value"
          class="cursor-pointer hover:bg-gray-50"
          @click="onClickWidget(stat)"
        />
      </div>
      <hr />
      <div class="flex items-center justify-between">
        <base-tab-nav :tabs="tabs" v-model="currentTab" @change="onChangeTab" />
        <div
          v-if="currentTab === 'history'"
          class="flex items-center justify-end gap-2"
        >
          <base-input
            :shadow="false"
            type="search"
            placeholder="Cari Mitra"
            debounce
            v-model="filter.search_office"
            @native-input="loadHistoryLogs"
          />
          <base-input
            :shadow="false"
            type="search"
            placeholder="Cari Telepon"
            debounce
            v-model="filter.search_phone"
            @native-input="loadHistoryLogs"
          />
          <base-select
            :shadow="false"
            :expand="false"
            :options="[
              { key: 'date', value: 'date', label: 'Per Tanggal' },
              { key: 'period', value: 'period', label: 'Per Periode' },
            ]"
            v-model="filter.filter_mode"
            @change="onChangeFilterMode"
          />
          <base-input
            v-show="filter.filter_mode === 'date'"
            :shadow="false"
            type="date"
            v-model="filter.date"
            @change="loadHistoryLogs"
          />
          <mitra-period-select
            v-show="filter.filter_mode === 'period'"
            v-model="filter.mitra_period_id"
            @change="onChangePeriod"
          />
          <base-select
            :expand="false"
            :shadow="false"
            :options="[
              { key: 'all', value: null, label: 'Status' },
              {
                key: 'dispatched',
                value: 'dispatched',
                label: 'Menunggu Antrian',
              },
              { key: 'created', value: 'created', label: 'Diproses' },
              { key: 'sent', value: 'sent', label: 'Terkirim' },
              { key: 'delivered', value: 'delivered', label: 'Diterima' },
              { key: 'read', value: 'read', label: 'Dibaca' },
              { key: 'failed', value: 'failed', label: 'Gagal' },
            ]"
            v-model="filter.status"
            @change="loadHistoryLogs"
          />
          <base-button @click="onExport" :loading="loadingExport.visible">{{
            loadingExport.visible ? loadingExport.text : 'Export'
          }}</base-button>
        </div>
      </div>

      <template>
        <datatable
          v-if="currentTab === 'history'"
          :total="historyLogs.meta.page.total"
          :perPage="historyLogs.meta.page.perPage"
          :currentPage="historyLogs.meta.page.currentPage"
          :columns="[
            { id: 'date', name: 'Tanggal' },
            { id: 'office_code', name: 'Kode Mitra' },
            { id: 'phone', name: 'No Telp' },
            { id: 'status', name: 'Status' },
          ]"
          :scroll-x="false"
          @pagechanged="onChangeHistoryPage"
        >
          <template #tbody="{ classes }">
            <tr
              v-for="historyLog in historyLogs.data"
              :key="historyLog.id"
              :class="[classes.tr, 'bg-white']"
            >
              <td :class="classes.td">
                {{ historyLog.attributes.createdAt | formatDate }}
              </td>
              <td :class="classes.td">
                {{ historyLog.attributes.office_code }}
              </td>
              <td :class="classes.td">
                {{ historyLog.attributes.phone }}
              </td>
              <td :class="classes.td">
                <base-badge
                  :color="getStatusBadge(historyLog.attributes.status).color"
                  :title="
                    historyLog.attributes.status === 'failed'
                      ? historyLog.attributes.failed_message
                      : ''
                  "
                  >{{
                    getStatusBadge(historyLog.attributes.status).text
                  }}</base-badge
                >
              </td>
            </tr>
          </template>
        </datatable>
        <datatable
          v-else
          :total="errorLogs.meta.page.total"
          :perPage="errorLogs.meta.page.perPage"
          :currentPage="errorLogs.meta.page.currentPage"
          :columns="[
            { id: 'date', name: 'Tanggal' },
            { id: 'office_code', name: 'Kode Mitra' },
            { id: 'phone', name: 'No Telp' },
            { id: 'message', name: 'Pesan' },
          ]"
          :scroll-x="false"
          @pagechanged="onChangeErrorPage"
        >
          <template #tbody="{ classes }">
            <tr
              v-for="errorLogs in errorLogs.data"
              :key="errorLogs.id"
              :class="[classes.tr, 'bg-white']"
            >
              <td :class="classes.td">
                {{ errorLogs.attributes.createdAt | formatDate }}
              </td>
              <td :class="classes.td">
                {{ errorLogs.attributes.office_code }}
              </td>
              <td :class="classes.td">
                {{ errorLogs.attributes.phone }}
              </td>
              <td :class="classes.td">
                {{ errorLogs.attributes.message }}
              </td>
            </tr>
          </template>
        </datatable>
      </template>
    </div>

    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { downloadFileUrl } from '@/services/utils.service';
import { mapGetters, mapActions } from 'vuex';
import BaseWidget from '@/components/base/BaseWidget.vue';
import BaseTabNav from '@/components/base/BaseTabNav.vue';
import MitraPeriodSelect from '@/components/period/mitra-period/mitra-period-select.vue';
import { sum } from '@/utils/array';

export default {
  components: { BaseWidget, BaseTabNav, MitraPeriodSelect },
  mixins: [requestMixin],
  data() {
    return {
      filter: {
        date: new Date(),
        status: null,
        search_phone: null,
        search_office: null,
        filter_mode: 'date',
        mitra_period_id: null,
      },
      loading: false,
      loadingExport: {
        text: null,
        visible: false,
      },
      historyLogs: {
        data: [],
        meta: {
          page: {},
        },
        reload: false,
      },
      errorLogs: {
        data: [],
        meta: {
          page: {},
        },
        reload: true,
      },
      stats: {
        total: 0,
        dispatched: 0,
        created: 0,
        sent: 0,
        delivered: 0,
        read: 0,
        failed: 0,
      },
      tabs: [
        { id: 'history', name: 'Riwayat Log', href: '#' },
        { id: 'error', name: 'Error Log', href: '#' },
      ],
      currentTab: 'history',
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    getStatusBadge(status) {
      return {
        total: {
          color: 'yellow',
          text: 'Total',
        },
        dispatched: {
          color: 'yellow',
          text: 'Menunggu Antrian',
        },
        created: {
          color: 'indigo',
          text: 'Diproses',
        },
        sent: {
          color: 'green',
          text: 'Terkirim',
        },
        delivered: {
          color: 'purple',
          text: 'Diterima',
        },
        read: {
          color: 'blue',
          text: 'Dibaca',
        },
        failed: {
          color: 'red',
          text: 'Gagal',
        },
      }[status];
    },
    async loadHistoryLogs(params) {
      this.loading = true;

      const [res, err] = await this.request(
        '/api/v1/member-activation-notifications',
        {
          params: {
            [this.filter.filter_mode === 'date'
              ? 'filter[date]'
              : 'filter[mitra_period_id]']:
              this.filter.filter_mode === 'date'
                ? this.filter.date
                : this.filter.mitra_period_id,
            'filter[status]': this.filter.status,
            'filter[search_phone]': this.filter.search_phone,
            'filter[search_office]': this.filter.search_office,
            'fields[member-activation-notifications]':
              'createdAt,office_code,phone,status',
            'page[size]': 5,
            ...params,
          },
        }
      );

      if (!err) {
        this.historyLogs = {
          ...res,
          reload: false,
        };

        if (params?.with_stats) {
          const baseStats = {
            dispatched: 0,
            created: 0,
            sent: 0,
            delivered: 0,
            read: 0,
            failed: 0,
          };

          this.stats = {
            total: sum(this.historyLogs.meta.stats, (stat) => stat.value),
            ...baseStats,
            ...Object.fromEntries(
              this.historyLogs.meta.stats.map((stat) => [
                stat.status,
                stat.value,
              ])
            ),
          };
        }
      }

      this.loading = false;
    },
    async loadErrorLogs(params) {
      this.loading = true;

      const [res, err] = await this.request(
        '/api/v1/notify-activated-member-invoice-errors',
        {
          params: {
            'fields[notify-activated-member-invoice-errors]':
              'createdAt,office_code,phone,message',
            'page[size]': 10,
            ...params,
          },
        }
      );

      if (!err) {
        this.errorLogs = {
          ...res,
          reload: false,
        };
      }

      this.loading = false;
    },
    async onChangeHistoryPage(page) {
      await this.loadHistoryLogs({
        'page[number]': page,
      });

      this.historyLogs.reload = true;
    },
    async onChangeErrorPage(page) {
      await this.loadErrorLogs({
        'page[number]': page,
      });

      this.errorLogs.reload = true;
    },
    onChangeFilterMode() {
      this.loadHistoryLogs({ with_stats: true });
    },
    onChangePeriod() {
      this.loadHistoryLogs({ with_stats: true });
    },
    async onChangeTab() {
      if (this.currentTab === 'history' && this.historyLogs.reload) {
        this.loadHistoryLogs();
      } else if (this.currentTab === 'error' && this.errorLogs.reload) {
        this.loadErrorLogs();
      }
    },
    onClickWidget(stat) {
      if (this.filter.filter_mode === 'date') {
        this.filter.filter_mode = 'period';
      }

      this.filter.status = stat === 'total' ? null : stat;

      this.loadHistoryLogs();
    },
    async onExport() {
      this.startLoadingExport('Mengirim data');

      const [, error] = await this.request(
        '/api/v1/member-activation-notifications/-actions/export',
        {
          method: 'post',
          data: {
            date: this.filter.date,
          },
        }
      );

      if (error) {
        this.createAlert({
          data: this.getRequestErrorMessage(error),
          status: 'error',
        });

        this.stopLoadingExport();
      }
    },
    stopListenExportNotification() {
      this.$pusher.unsubscribe(`private-App.Models.User.${this.me.id}`);
    },
    startListenExportNotification() {
      const userChannel = this.$pusher.subscribe(
        `private-App.Models.User.${this.me.id}`
      );

      userChannel.bind(
        'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
        (e) => {
          if (
            e.type ===
            'App\\Notifications\\MemberActivationNotificationExported'
          ) {
            if (e.status === 'processing') {
              this.loadingExport.text = 'Sedang Diproses';
            } else if (e.status === 'failed') {
              this.stopLoadingExport();

              this.createAlert({
                data: 'Gagal export',
                status: 'error',
              });
            } else if (e.status === 'finished') {
              this.stopLoadingExport();

              downloadFileUrl(e.file_url, 'test.csv');
            }
          }
        }
      );
    },
    startLoadingExport(text) {
      this.loadingExport.text = text;
      this.loadingExport.visible = true;
    },
    stopLoadingExport() {
      this.loadingExport.text = null;
      this.loadingExport.visible = false;
    },
  },
  created() {
    this.filter.mitra_period_id = this.me.current_period.id;

    this.loadHistoryLogs({
      with_stats: true,
    });
    this.startListenExportNotification();
  },
};
</script>
