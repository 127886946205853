<template>
  <nav class="flex space-x-4">
    <a
      v-for="tab in tabs"
      :key="tab.id"
      href="#"
      :class="[
        currentTab === tab.id
          ? 'bg-gray-200 text-gray-800'
          : 'text-gray-600 hover:text-gray-800',
        'rounded-md px-3 py-2 text-sm font-medium',
      ]"
      @click="onClick(tab)"
      >{{ tab.name }}</a
    >
  </nav>
</template>

<script>
export default {
  props: {
    tabs: Array,
    value: String
  },
  emits: ['input', 'change'],
  computed: {
    currentTab: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    onClick(tab) {
      if (tab.id !== this.currentTab) {
        this.currentTab = tab.id

        this.$emit('change')
      }
    }
  }
}
</script>